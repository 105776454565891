import React, { useEffect, useState } from "react";
import style from "./boards-grid.module.css";
import { Board, BoardState } from "shared/datamodel/schemas/board";
import { useRouter } from "next/router";
import useStateValue from "frontend/state/value";
import AppHeader from "frontend/app-header/app-header";
import { createBoard } from "frontend/services/boardsService";
import useAuthentication from "frontend/hooks/use-authentication";
import Modal from "frontend/modal/modal";
import ErrorModal from "frontend/error/error-modal";
import MaxUsersPerBoardError from "frontend/error/max-users-per-board-error";
import StyledButton from "frontend/ui-components/styled-button";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import EditSeatsModal from "frontend/modals/edit-seats-modal";
import { useAtom, useAtomValue } from "jotai";
import { isBannerNotificationOnAtom, isBusyAtom } from "state-atoms/general-atoms";
import { BlankCanvasId, CanvasTemplate } from "shared/datamodel/schemas/canvas-template";
import TemplatesSection from "./templates-section";
import { SideMenu } from "./side-menu/side-menu";
import Checkout from "frontend/billing/checkout";
import classNames from "classnames";
import { InviteMode, userTipsAtom } from "state-atoms";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import CollaborationHeader from "frontend/modals/collaboration/collaboration-header";
import { PortalID } from "frontend/modal/portal-types";
import { Account, TipName } from "shared/datamodel/schemas";
import MoveCanvasModals from "frontend/modals/collaboration/move-canvas-modal";
import MoveCanvasToast from "frontend/ui-components/move-canvas-toast";
import UpgradeModalAfterDowngrade from "frontend/modals/upgrade/upgrade-after-downgrade";
import useFeatureValue from "frontend/hooks/use-features";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import ModalManager, { ModalAction, ModalType } from "frontend/modals/modal-manager";
import { RESET } from "jotai/utils";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModal from "frontend/modals/invite-modal";
import RestrictedUserNoticeModal from "frontend/modals/restricted-user-notice-modal";
import { markTipSeen } from "frontend/api";
import { getPathPrefix } from "../utils/getPathPrefix";
import { SearchSection } from "frontend/boards-grid/search-section/search-section";
import { useRecentSelected } from "frontend/boards-grid/search-section/hooks/use-recent-selected";
import ManageCollaborationMembers from "frontend/modals/collaboration/manage-collaboration-members";
import MenuToggle from "./side-menu/menu-toggle";
import SideMenuNew from "./side-menu/side-menu-new";
import BoardsBySection, { BoardSection, BoardSectionType } from "./boards-by-section";
import useBoards from "frontend/hooks/use-boards";

export type GridError = {
  type: "limitReached";
  data: string | number;
};

export function BoardsGridNew({ showGridError }: { showGridError?: GridError | null }) {
  enum AdminModalType {
    inviteModal = 0,
    EditSeatsModal = 1,
  }

  const [{ user }, dispatch] = useStateValue();
  const [error, setError] = useState<JSX.Element | null>(null);
  const { isReady } = useAuthentication(true);
  const [adminModalTypeToShow, setAdminModalTypeToShow] = useState<AdminModalType | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeNoticeModal, setShowDowngradeNoticeModal] = useState<boolean>(false);
  const [showSubscriptionCanceledNotice, setShowSubscriptionCanceledNotice] = useState<boolean>(false);
  const [showRestrictedNoticedModal, setShowRestrictedNoticeModal] = useState<boolean>(false);
  const [showAccountMembers, setShowAccountMembers] = useState(false);
  const [canvasToMove, setCanvasToMove] = useState<Board | null>(null);
  const [isNewMenu, setIsNewMenu] = useState(false);
  const [isBusy, setIsBusy] = useAtom(isBusyAtom);
  const userTips = useAtomValue(userTipsAtom);
  const isBannerNotificationOn = useAtomValue(isBannerNotificationOnAtom);
  const [canvasMovingMessage, setCanvasMovingMessage] = useState<JSX.Element | null>(null);
  const { onSelected } = useRecentSelected();

  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [inviteSource, setInviteSource] = useState<string>("board-grid-app-header");
  const [selectedSection, setSelectedSection] = useState<BoardSection>({
    id: 0,
    name: "My Canvases",
    type: BoardSectionType.MY_BOARDS,
  });
  const { canCreateInstance, canPerformAnyAction } = useAbility();
  const instance = selectedProject ?? selectedTeam;
  const instanceType = selectedProject ? InstanceType.Project : InstanceType.Team;
  const shouldShoWRestrictedModal = user && instance && !canPerformAnyAction(user, instance, instanceType);

  const maxAllowedCanvasesString = useFeatureValue(consts.FEATURE_NAMES.MAX_ALLOWED_CANVASES);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";

  const { setModalDeepLink } = useModalDeepLink();
  const isMondayUser = user?.account?.id === "5";

  const router = useRouter();
  const { boards } = useBoards();

  useEffect(() => {
    if (router && router.query.board_users_limit && router.query.maxAllowedCollaborators) {
      const boardName = decodeURIComponent(router.query.board_users_limit.toString());
      const maxAllowedCollaborators = decodeURIComponent(router.query.maxAllowedCollaborators.toString());
      router.query = {};
      setError(
        <MaxUsersPerBoardError
          boardName={boardName}
          onDismiss={() => {
            setError(null);
            router.replace("/");
          }}
          maxAllowedCollaborators={Number.parseInt(maxAllowedCollaborators)}
        />
      );
    }
  }, [router]);

  function showUpgradeRequiredModal(numberOfCanvases: number) {
    if (numberOfCanvases === 0) {
      return null;
    }
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.CANVAS_LIMIT_REACHED);
    return (
      <ErrorModal title="Having fun, are we?" onDismiss={() => setError(null)}>
        <span>
          You have reached the <b>{`${numberOfCanvases}`} canvas</b> limit for this free version. Upgrade to get
          unlimited canvases.
        </span>
        <StyledButton
          title="Upgrade"
          onClick={() => {
            tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
            tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "upgrade-board-card");
            setShowUpgradeModal(true);
            setError(null);
          }}
        />
      </ErrorModal>
    );
  }

  useEffect(() => {
    if (showGridError) {
      switch (showGridError.type) {
        case "limitReached": {
          setError(showUpgradeRequiredModal(showGridError.data as number));
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [showGridError]);

  useEffect(() => {
    if (user?.planInfo?.is_downgraded && user.isAdmin && !userTips.has(TipName.downgradeNotice)) {
      setShowDowngradeNoticeModal(true);
    }
    if (
      user?.planInfo?.is_downgraded &&
      user?.planInfo?.was_subscription_cancelled &&
      user.isAdmin &&
      !userTips.has(TipName.subscriptionCanceledNotice)
    ) {
      setShowSubscriptionCanceledNotice(true);
    }
    if (applyRestrictedLimitation && !userTips.has(TipName.restrictedUsersNotice) && shouldShoWRestrictedModal) {
      setShowRestrictedNoticeModal(true);
    }
  }, [
    userTips,
    applyRestrictedLimitation,
    shouldShoWRestrictedModal,
    user?.isAdmin,
    user?.planInfo?.is_downgraded,
    user?.planInfo?.was_subscription_cancelled,
  ]);

  //Hide the move canvas message after some interval
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (canvasMovingMessage) {
      timeOut = setTimeout(() => {
        setCanvasMovingMessage(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [canvasMovingMessage]);

  useEffect(() => {
    if (!user?.canInvite && adminModalTypeToShow === AdminModalType.inviteModal) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.INVITE_ACTION, "no_permission_to_invite_modal", "invite-to-account");
    }
  }, [user?.canInvite, adminModalTypeToShow, AdminModalType.inviteModal]);

  useEffect(() => {
    if (selectedProject) {
      setSelectedSection({ id: selectedProject.id, name: selectedProject.name, type: BoardSectionType.PROJECT });
    } else if (selectedTeam) {
      setSelectedSection({ id: selectedTeam.id, name: selectedTeam.name, type: BoardSectionType.TEAM });
    }
  }, [selectedTeam, selectedProject]);

  function onMyCanvasesClick() {
    setSelectedSection({ id: 0, name: "My Canvases", type: BoardSectionType.MY_BOARDS });
    setSelectedTeam(null);
    setSelectedProject(null);
  }

  function onEditSeats() {
    setAdminModalTypeToShow(AdminModalType.EditSeatsModal);
  }

  function showNoPermissionToCreateModal() {
    if (shouldShoWRestrictedModal) {
      return <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setError(null)} />;
    }
    return (
      <Modal dimBackground={true} onClickBackground={() => setError(null)}>
        <div className={style.contactYourAdmin}>
          <CollaborationHeader
            title={`You are not an editor in this ${instanceType}`}
            subtitle={`You are viewer in this ${instanceType}, to have editing permissions and create more canvases, please request access from the ${instanceType} owner.`}
            onDismiss={() => setError(null)}
          />
          <StyledButton title={"Got it"} customStyle={{ width: "107px" }} onClick={() => setError(null)} />
        </div>
      </Modal>
    );
  }

  function getTeamAndProjectForNewCanvas({ teamId, projectId }: { projectId?: number | null; teamId?: number | null }) {
    if (projectId === null && teamId === null) {
      return { teamId: null, projectId: null };
    }
    const instance = selectedProject ?? selectedTeam;
    const instanceType = selectedProject ? InstanceType.Project : InstanceType.Team;

    if (user && instance && !canCreateInstance(user, instance, instanceType)) {
      setError(showNoPermissionToCreateModal());
      setIsBusy(false);
      return { teamId: null, projectId: null };
    }
    if (!selectedTeam && teamId === undefined) {
      return { teamId: null, projectId: null };
    }
    const finalTeamId = teamId ?? selectedTeam?.id ?? null;
    const finalProjectId = projectId ?? selectedProject?.id ?? null;
    return { teamId: finalTeamId, projectId: finalProjectId };
  }

  async function createNewBoard(
    template: Omit<CanvasTemplate, "data"> & { data?: Record<string, unknown> },
    inputProjectId?: number | null,
    inputTeamId?: number | null
  ) {
    setIsBusy(true);
    if (template.upgradeRequired) {
      setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
      setIsBusy(false);
      return;
    }

    const maxAllowedCanvases = Number.parseInt(maxAllowedCanvasesString);

    if (boards.length >= maxAllowedCanvases) {
      setError(showUpgradeRequiredModal(maxAllowedCanvases));
      setIsBusy(false);
      return;
    }

    const { teamId, projectId } = getTeamAndProjectForNewCanvas({ teamId: inputTeamId, projectId: inputProjectId });

    try {
      const userName = user?.name.split(" ")[0];
      const name = template.id == BlankCanvasId ? (userName ? `${userName}'s Canvas` : "New Canvas") : template.name;
      const boardState = BoardState.templateNotSetUp;
      const board = await createBoard(name, boardState, template.id, projectId, teamId, dispatch);
      if (board) {
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.CANVAS_ACTION, // category
          "new_canvas", // action
          template.name, // string value
          board.accountId // property
        );

        window.sessionStorage.setItem(consts.SESSION_STORAGE_KEYS.FIRST_ACCESS, "true");
        if (template.data) {
          window.sessionStorage.setItem(
            consts.SESSION_STORAGE_KEYS.CREATED_TEMPLATE_DATA + board.documentId,
            JSON.stringify(template.data)
          );
        }
        board.createdFromTemplateId
          ? router.push(`/d/${board.documentId}`)
          : router.push(`/d/${board.documentId}#show=templateLibrary`);
      }
    } catch (error: unknown) {
      if ((error as { response?: { status: number; data: { maxBoardsAllowed: number } } })?.response?.status === 406) {
        const typedError = error as { response: { data: { maxBoardsAllowed: number } } };
        setError(showUpgradeRequiredModal(typedError.response.data.maxBoardsAllowed));
      }
    } finally {
      setIsBusy(false);
    }
  }

  function onChooseTemplate({ template }: { template: CanvasTemplate }) {
    setModalDeepLink({ modalType: RESET });
    tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "canvas_template_selected_main_screen", template.id);
    createNewBoard(template);
  }

  function handleModalAction(action: ModalAction) {
    if (action) {
      switch (action.type) {
        case "chooseTemplate": {
          onChooseTemplate(action.payload);
          break;
        }
      }
    }
  }

  function onInviteClick(title?: string) {
    const underScoredTitle = title ? `${title.toLowerCase().replaceAll(" ", "_")}_` : "";
    title && setInviteSource(underScoredTitle);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      `${underScoredTitle}invite_button_clicked`,
      "invite_type",
      "account"
    );
    if (shouldShoWRestrictedModal) {
      setError(showNoPermissionToCreateModal());
    } else {
      setAdminModalTypeToShow(AdminModalType.inviteModal);
    }
  }

  function handleNoPermissionToCreate() {
    setError(showNoPermissionToCreateModal());
    setIsBusy(false);
  }

  return (
    <div
      id={"boards-container"}
      className={classNames(style.container, style.containerMonday, {
        [style.containerWithBanner]: isBannerNotificationOn,
      })}
    >
      <AppHeader
        isBoardHeader={false}
        showDowngradedNotification={user?.planInfo?.is_downgraded}
        onInviteClick={onInviteClick}
        onRestrictedClick={() => setError(showNoPermissionToCreateModal())}
      >
        <StyledButton
          title="Invite People"
          icon={getPathPrefix("/images/plus-icon.svg")}
          color="#1973FF"
          onClick={() => onInviteClick()}
          customStyle={{ letterSpacing: "0.124px" }}
        />
      </AppHeader>
      {adminModalTypeToShow !== null && selectedTeam && (
        <Modal dimBackground={true}>
          {adminModalTypeToShow === AdminModalType.EditSeatsModal ? (
            <EditSeatsModal onDismiss={() => setAdminModalTypeToShow(null)} />
          ) : (
            <InviteModal
              team={selectedTeam}
              onDismiss={() => setAdminModalTypeToShow(null)}
              onEditSeats={onEditSeats}
              inviteMode={InviteMode.Edit}
              source={inviteSource}
            />
          )}
        </Modal>
      )}
      <div
        className={style.contentBackground}
        style={{
          transform: "none",
        }}
      />
      <div className={classNames(style.left, { [style.leftWithBanner]: isBannerNotificationOn && isMondayUser })}>
        {
          <>
            <MenuToggle isNewMenu={isNewMenu} setIsNewMenu={setIsNewMenu} />
            {isNewMenu ? (
              <SideMenuNew
                selectedTeam={selectedTeam}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                createNewBoard={createNewBoard}
                onEditSeats={onEditSeats}
                setShowAccountMembers={setShowAccountMembers}
                onMyCanvasesClick={onMyCanvasesClick}
              />
            ) : (
              <SideMenu
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                setShowTemplatesModal={(show) =>
                  show
                    ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
                    : setModalDeepLink({ modalType: ModalType.None })
                }
                createNewBoard={createNewBoard}
                handleNoPermissionToCreate={handleNoPermissionToCreate}
                onEditSeats={onEditSeats}
              />
            )}
          </>
        }
      </div>
      <div
        className={style.searchTemplates}
        style={{
          pointerEvents: "auto",
        }}
      >
        <SearchSection />
      </div>
      <div
        className={style.templates}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && (
          <TemplatesSection
            onCreateBoard={createNewBoard}
            onPreviewClicked={(template) => {
              setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
            }}
            setShowTemplatesModal={(show) =>
              show
                ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
                : setModalDeepLink({ modalType: ModalType.None })
            }
          />
        )}
      </div>
      <div
        className={style.contentArea}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && (
          <div
            className={style.contentArea}
            style={{
              transform: "none",
              pointerEvents: isBusy ? "none" : "auto",
            }}
          >
            <BoardsBySection
              section={selectedSection}
              onSelected={onSelected}
              createNewBoard={createNewBoard}
              onDuplicateError={(number) => setError(showUpgradeRequiredModal(number))}
              setCanvasToMove={setCanvasToMove}
              userId={user?.id}
              accountId={user?.account?.id}
            />
          </div>
        )}
      </div>
      <div className={style.footer}>
        <div className={style.logo}>
          <span>By</span>
          <img src={getPathPrefix("/images/monday-logo.svg")} alt="" />
        </div>
        <a href="https://monday.com/l/legal/tos/">Terms of use</a>
        <a href="https://monday.com/l/privacy/privacy-policy/">Privacy policy</a>
      </div>
      <ModalManager
        setIsLoading={setIsBusy}
        handleModalAction={handleModalAction}
        onShowUpgradeModal={() => {
          setModalDeepLink({ modalType: RESET });
          setShowUpgradeModal(true);
        }}
      />
      {error && <Modal dimBackground={true}>{error}</Modal>}
      {showUpgradeModal && (
        <Checkout
          user={user}
          onDismiss={() => {
            setShowUpgradeModal(false);
          }}
        />
      )}
      {canvasToMove && (
        <Modal dimBackground={true}>
          <MoveCanvasModals
            board={canvasToMove}
            onDismiss={() => setCanvasToMove(null)}
            setCanvasMovingMessage={setCanvasMovingMessage}
            currentProject={selectedProject}
          />
        </Modal>
      )}
      {canvasMovingMessage && (
        <MoveCanvasToast content={canvasMovingMessage} onClose={() => setCanvasMovingMessage(null)} />
      )}
      {showDowngradeNoticeModal && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowDowngradeNoticeModal}
          setShowUpgradeModal={setShowUpgradeModal}
          type="downgrade"
        />
      )}
      {showSubscriptionCanceledNotice && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowSubscriptionCanceledNotice}
          setShowUpgradeModal={setShowUpgradeModal}
          type="subscriptionCanceled"
        />
      )}
      {showRestrictedNoticedModal && (
        <RestrictedUserNoticeModal
          trackingEvent={"home"}
          onDismiss={() => {
            markTipSeen(TipName.restrictedUsersNotice);
            setShowRestrictedNoticeModal(false);
          }}
        />
      )}
      {showAccountMembers && (
        <Modal>
          <ManageCollaborationMembers
            instance={user?.account as Account}
            type={InstanceType.Account}
            onDismiss={() => setShowAccountMembers(false)}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      <div id={PortalID.Floaters} />
      <div id={PortalID.Tooltips} />
    </div>
  );
}
