import { projectCacheAtom } from "state-atoms";
import { Project, ProjectCache } from "shared/datamodel/schemas/project";
import { getProjectsByIds as getProjectsByIdsFromDB } from "frontend/api";
import { useGenericCache } from "./use-general-cache";

export function useProjectsCache() {
  const { getItems, isLoading } = useGenericCache<Project, ProjectCache>({
    cacheAtom: projectCacheAtom,
    fetchByIds: getProjectsByIdsFromDB,
    entityName: "projects",
    entityKey: "project",
  });

  return { getProjects: getItems, isLoading };
}
